import React,{useEffect,useState} from 'react';
import img from '../images/confirm.svg'
import {
  currencyFormatDE,
} from '../utils/globals';
import moment from 'moment';
import Footer from "../components/Footer";
const succesPage = ({location})=>{
//   console.log("llegue",location.state)
  /*useEffect(() => {
    document.getElementById("root").style.background="#FAFAFA"
    return ()=>{
      document.getElementById("root").style.background="#fff"
    }
  })
  if(!location.state){
    return (<h4>Error en la página solicitada</h4>)
  }*/
  return(
    <>
        <section className='sp_bg'>
            <div className="payment_succes_header">
                <img src="https://ticketmundoblob.blob.core.windows.net/skin/logo_20.jpeg" width="249" alt="" />
                <img src="https://cdn.ticketmundo.live/commons/pending.gif" width="56" alt="pending" style={{margin: `14px 0 0`}}/>
                <div className='text_header'>
                    <span style={{fontSize: '40px'}}>{location.state.Nombre}, tu pago está en proceso</span>
                    <span> realizamos una reserva de tu orden</span>
                    <span style={{fontSize: '15px',fontWeight: '400'}}> se enviaron detalles al correo</span>
                    <span>{location.state.Email}</span>
                </div>
            </div>
            <section className="container-custom-tm clear-fl wrap-pago wrap_succes_payment">
                <div className='content'>
                    <div className="title-g sub-title-bold color-titulos">
                        <span>Puntos Autorizados de pago</span>
                    </div>
                    <div className='white_cont'>
                        <h3 className='title_pay'>{location.state.selectedFunction.Ciudad}</h3>
                        Horarios de atención:
                        <ul className='ul_tickets'>
                            <li>
                                <label>Taquilla Ticketmundo CCCT</label>
                                LUN - SAB 09:00 AM - 05:00 PM
                            </li>
                            {/*
                            <li>
                                <label>Taquilla Ticketmundo CCCT</label>
                                LUN - VIE 09:00 AM - 06:00 PM SAB 10:00 AM - 8:00PM
                            </li>
                            <li>
                                <label>Taquilla Ticketmundo CCCT</label>
                                LUN - VIE 09:00 AM - 06:00 PM SAB 10:00 AM - 8:00PM
                            </li> */}
                        </ul>
                    </div>
                    <div className='white_cont' style={{display: 'flex',alignItems: 'center',gap: '10px',marginTop: '30px'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="32" viewBox="0 0 34 32" fill="none">
                            <rect x="1" y="4.18262" width="32.1977" height="26.3059" stroke="#5CC8CA" strokeWidth="1.5"/>
                            <line x1="1.89453" y1="10.2681" x2="34.0002" y2="10.2681" stroke="#5CC8CA" strokeWidth="1.5"/>
                            <line x1="8.22266" x2="8.22266" y2="7.39886" stroke="#5CC8CA" strokeWidth="1.5"/>
                            <line x1="27.3828" x2="27.3828" y2="7.39886" stroke="#5CC8CA" strokeWidth="1.5"/>
                            <rect x="8.15625" y="14.3101" width="3.57752" height="3.57752" fill="#5CC8CA"/>
                            <rect x="22.4648" y="14.3101" width="3.57752" height="3.57752" fill="#5CC8CA"/>
                            <rect x="15.3125" y="14.3101" width="3.57752" height="3.57752" fill="#5CC8CA"/>
                            <rect x="8.15625" y="21.4648" width="3.57752" height="3.57752" fill="#5CC8CA"/>
                            <rect x="22.4648" y="21.4648" width="3.57752" height="3.57752" fill="#5CC8CA"/>
                            <rect x="15.3125" y="21.4648" width="3.57752" height="3.57752" fill="#5CC8CA"/>
                        </svg>
                        <div>
                            Tu reserva vence el <b>{`${moment.utc(location.state.FechaFinReserva).format("dddd DD MMMM")} 
                            ${ location.state.global.formatoFecha ==="12hr" ? ` ${moment(location.state.FechaFinReserva).format("hh:mm a")}` : ` ${moment(location.state.FechaFinReserva).format("HH:mm")} Hrs`}`}</b>
                        </div>
                    </div>
                    <div className="title-g sub-title-bold color-titulos">
                        <span>Indicaciones</span>
                    </div>
                </div>
                <div className='resumen'>
                    <div className="title-g sub-title-bold color-titulos">
                        <span>Detalle de los tickets</span>
                    </div>
                    <div className='white_cont'>
                        <img src={`https://ticketmundoblob.blob.core.windows.net/imagenestmusaprueba/Evento_${location.state.selectedFunction.EventoId}_517_262.jpeg`} alt="" className='imag_pay'/>
                        <div className="title-g sub-title-bold color-titulos">
                            <span>{location.state.selectedFunction.EventoNombre.toLowerCase()}</span>
                            <p style={{fontWeight: '400',fontSize: '15px',lineHeight: '20px'}}>{moment.utc(location.state.selectedFunction.Fecha).format("dddd DD MMMM")} {moment.utc(location.state.selectedFunction.Fecha).format(location.state.global.formatoFecha ==="12hr"? "hh:mm": "HH:mm") +" hrs"}</p>
                            <p style={{fontWeight: '400',fontSize: '15px',lineHeight: '20px'}}>{location.state.selectedFunction.RecintoNombre} </p>
                        </div>
                        <p>Vip 2</p>
                    </div>
                </div>
                <div className='white_cont' style={{display: 'flex',width: '100%',marginBottom:'30px'}}>
                    <ul className='ul_'>
                        <li>Presenta el codigo de reserva enviado a tu correo electrónico en los puntos autorizados de pago.</li>
                        <li>Los billetes deben estar en buen estado , no se aceptaran billetes rotos o marcados.</li>
                        <li>No es necesario imprimir la reserva</li>
                        <li>Debes llevar el monto completo en Dolares. ( vuelto se paga en pago movil montos menores de $15.00 )</li>
                        <li>No se aceptan pagos parciales</li>  
                        <li>Una vez confirmado el pago recibiran en los proximos 15 minutos el e-ticket</li>
                        <li>Cualquier duda, sugerenca o reclamo escribanos <a href='https://wa.me/+584123604583' target="_blank" style={{color: '#000'}}>+584123604583</a></li>
                    </ul>
                </div>
            </section>
        </section>
        <Footer />
    </>
  )
}

export default succesPage;