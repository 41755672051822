import React from "react";
export const Place = () => (
    <svg
        width="16"
        height="22"
        viewBox="0 0 16 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginRight: "5px" }}
    >
        <path
            d="M14.8257 8.46779C14.8257 4.68614 11.7307 1.62061 7.91278 1.62061C4.09491 1.62061 1 4.68614 1 8.46779C1 13.5131 7.91278 19.9999 7.91278 19.9999C7.91278 19.9999 14.8257 13.5131 14.8257 8.46779Z"
            stroke="var(--c1)"
            strokeWidth="1.5"
        />
        <ellipse
            cx="7.91266"
            cy="8.54955"
            rx="3.09259"
            ry="3.06322"
            stroke="var(--c1)"
            strokeWidth="1.5"
        />
    </svg>
);
export const Map = () => (
    <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.8937 0.957801C18.6473 0.764943 18.3604 0.630597 18.0544 0.564911C17.7485 0.499226 17.4317 0.503918 17.1279 0.578634L13.3037 1.5428C13.1465 1.57533 12.9843 1.57533 12.827 1.5428L7.43204 0.199467C7.10415 0.123615 6.76326 0.123615 6.43537 0.199467L1.87454 1.3478C1.42898 1.45761 1.03313 1.71369 0.750311 2.07507C0.467493 2.43645 0.314053 2.88224 0.314538 3.34113V15.4203C0.311669 15.7339 0.381598 16.0438 0.518825 16.3258C0.656051 16.6078 0.856831 16.8541 1.10537 17.0453C1.35175 17.2382 1.63873 17.3725 1.94463 17.4382C2.25054 17.5039 2.56738 17.4992 2.87121 17.4245L6.69537 16.4603C6.8526 16.4278 7.01481 16.4278 7.17204 16.4603L12.567 17.8036C12.7309 17.8383 12.8979 17.8564 13.0654 17.8578C13.2328 17.8558 13.3997 17.8377 13.5637 17.8036L18.1245 16.6553C18.5701 16.5455 18.9659 16.2894 19.2488 15.928C19.5316 15.5666 19.685 15.1209 19.6845 14.662V2.5828C19.6874 2.26922 19.6175 1.95926 19.4803 1.67728C19.343 1.39531 19.1422 1.14903 18.8937 0.957801ZM6.39204 15.4203L2.6112 16.3628C2.46746 16.4013 2.31676 16.4059 2.17091 16.3764C2.02506 16.347 1.88802 16.2841 1.7705 16.1928C1.65299 16.1016 1.5582 15.9843 1.49355 15.8503C1.42891 15.7163 1.39616 15.5691 1.39787 15.4203V3.34113C1.39852 3.12452 1.47128 2.9143 1.60468 2.74363C1.73807 2.57297 1.9245 2.45158 2.13454 2.39863L6.39204 1.32613V15.4203ZM12.5237 16.677L7.47537 15.4203V1.32613L12.5237 2.5828V16.677ZM18.6012 14.662C18.6006 14.8786 18.5278 15.0888 18.3944 15.2595C18.261 15.4301 18.0746 15.5515 17.8645 15.6045L13.607 16.677V2.5828L17.3879 1.6403C17.5326 1.6031 17.684 1.59937 17.8304 1.6294C17.9768 1.65944 18.1144 1.72244 18.2329 1.81363C18.3494 1.90479 18.4433 2.02164 18.5072 2.15506C18.571 2.28849 18.6032 2.43488 18.6012 2.5828V14.662Z"
            fill="#353B47"
        />
    </svg>
);
export const Share = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 17.619"
    >
        <g id="share" transform="translate(-419.5 -998.381)">
            <path
                id="Trazado_3253"
                data-name="Trazado 3253"
                d="M274.5,301.774V308.5h-16v-6.726"
                transform="translate(162 706.5)"
                fill="none"
                stroke="var(--c4)"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <g
                id="Grupo_2465"
                data-name="Grupo 2465"
                transform="translate(0.532)"
            >
                <path
                    id="Trazado_3254"
                    data-name="Trazado 3254"
                    d="M338.25,292.5H344.2v5.953"
                    transform="translate(-22.54 1036.355) rotate(-45)"
                    fill="none"
                    stroke="var(--c4)"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <line
                    id="Línea_536"
                    data-name="Línea 536"
                    y2="9"
                    transform="translate(427.677 999.5)"
                    fill="none"
                    stroke="var(--c4)"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
            </g>
        </g>
    </svg>
);

export const NewBack = () => (
    <svg
        width="19"
        height="17"
        viewBox="0 0 19 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ width: "20px", height: "20px", marginRight: "15px" }}
    >
        <path
            d="M8.89551 15.689L1.55096 8.34442L8.89554 0.999836"
            stroke="#333"
            strokeWidth="2"
        />
        <line
            x1="3"
            y1="8.40771"
            x2="19"
            y2="8.40771"
            stroke="#333"
            strokeWidth="2"
        />
    </svg>
);
